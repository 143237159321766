<template>
  <div class="main">
    <div class="article">
      <div class="header clear-fix">
        <h3>{{ article.title }}</h3>
        <span class="time"
          >时间：{{ article.releaseTime }} 来源人：{{ article.source }}</span
        >
      </div>
      <div>
        <div class="contents" v-html="article.content"></div>
      </div>
      <div class="beforeafter">
        <div
          class="before"
          v-if="JSON.stringify(this.article.before) != '{}'"
          @click="goarticle(article.before.articleId)"
        >
          上一页：{{ beforetitle }}
        </div>
        <div
          class="after"
          v-if="JSON.stringify(this.article.after) != '{}'"
          @click="goarticle(article.after.articleId)"
        >
          下一页：{{ aftertitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Membership-Detail",
  data() {
    return {
      article: {
        title: "",
        releaseTime: "",
        source: "",
        content: "",
      },
      activityList: [],
      recommendList: [],
      beforetitle: "",
      aftertitle: "",
    };
  },
  created() {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    goarticle(articleId) {
      this.$router.push("/membership-detail?id=" + articleId);
      this.getDetail();
    },
    getDetail() {
      request
        .post("/in/website/articleDetails", { articleId: this.$route.query.id })
        .then((res) => {
          console.log(res.data);
          this.article = res.data;
          this.beforetitle = this.article.before.title;
          this.aftertitle = this.article.after.title;
        });
    },
  },
  watch: {
    $route(to) {
      console.log(to.query.id);
      this.getDetail();
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background-color: #f7f7f7;
}
.article {
  display: inline-block;
  width: 1200px;
  min-height: 1098px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 50px;
  margin-left: 360px;
  margin-bottom: 130px;
  padding-top: 30px;
  padding-left: 55px;
  padding-right: 50px;
  .header {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
    margin-bottom: 37px;
    h3 {
      line-height: 31px;
      font-size: 22px;
      font-weight: 700;
      color: #333333;
      text-align: center;
    }
    .time {
      float: right;
      margin-right: 130px;
      margin-top: 15px;
      font-size: 14px;
      color: #666666;
    }
  }
  .contents {
    min-height: 750px;
    word-wrap: break-word;
  }
  .contents/deep/p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    color: #333333;
    font-family: "PingFang SC", "PingFang SC-Medium";
    margin-top: 30px;
  }
  .contents/deep/img {
    max-width: 1080px;
    height: 100%;
    margin: 0 auto;
    display: flex;
  }
  .contents/deep/figure {
    max-width: 1080px;
    height: 100%;
    margin: 0 auto;
    display: flex;
  }
  .beforeafter {
    padding-top: 30px;
    margin-bottom: 53px;
    border-top: 1px solid #eeeeee;
    margin-top: 50px;
    .before,
    .after {
      margin-bottom: 10px;
      cursor: pointer;
    }
    .before:hover,
    .after:hover {
      color: #ff7b00;
    }
  }
}
</style>